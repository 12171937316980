"use strict";
var TemplateEngine = function (html, options) {
    var re = /<%([^%>]+)?%>/g, reExp = /(^( )?(if|for|else|switch|case|break|{|}))(.*)?/g, code = 'var r=[];\n', cursor = 0, match;
    var add = function (line, js) {
        js ? (code += line.match(reExp) ? line + '\n' : 'r.push(' + line + ');\n') :
            (code += line != '' ? 'r.push("' + line.replace(/"/g, '\\"') + '");\n' : '');
        return add;
    };
    while (match = re.exec(html)) {
        add(html.slice(cursor, match.index))(match[1], true);
        cursor = match.index + match[0].length;
    }
    add(html.substr(cursor, html.length - cursor));
    code += 'return r.join("");';
    return new Function(code.replace(/[\r\t\n]/g, '')).apply(options);
};

function getbasket() {
    $.ajax({
        url: window.routeCartAjax,
        type: "GET",
        dataType: 'json',
        success: function (data) {
            if (data.status == "success") {
                var json_obj = data;//parse JSON

                var template = '<div class="cart_item">\n' +
                    '                                        <div class="cart_img">\n' +
                    '                                            <a href="<%this.link%>"><img src="<%this.img%>" alt="<%this.name%>"></a>\n' +
                    '                                        </div>\n' +
                    '                                        <div class="cart_info">\n' +
                    '                                            <a href="<%this.link%>"><%this.name%>\n' +
                    '                                            </a>\n' +
                    '\n' +
                    '                                            <span class="quantity">x <%this.qty%></span>\n' +
                    '                                            <span class="price_cart"><%this.price%>  <%this.currency%></span>\n' +
                    '\n' +
                    '                                        </div>\n' +
                    '                                        <div class="cart_remove">\n' +
                    '                                            <a href="#" data-id="<%this.dataid%>" class="header-cart-delete btn-remove" title="Remove Product"><i class="ion-android-close"></i></a>\n' +
                    '                                        </div>\n' +
                    '                                    </div>';


                var output = "";

                $.each(data.basket.items, function (index, value) {
                    var price = ((value.price / 100) * window.exchangeratio).toFixed(2);
                    output += TemplateEngine(template, {
                        img: window.cdnurl + value.options.image,
                        link: window.routeProductSlug + value.options.slug,
                        name: value.name,
                        price: price,
                        qty: value.qty,
                        currency: window.exchangecurrency,
                        dataid: value.rowId
                    });

                });

                $('#cart-items').html(output);


                var totalPrice = $('.cart-total-price');
                var totalPiece = $('.cart-piece');
                //var totalPieceData = Object.keys(data.basket.items).length;
                var totalPieceData = data.basket.items_total;

                totalPrice.html(data.basket.subtotal);
                totalPiece.html(totalPieceData);
                if (totalPieceData == 0) {
                    $('.cart-items-wrap').hide();
                    $('.empty-cart').show();
                } else {
                    $('.cart-items-wrap').show();
                    $('.empty-cart').hide();
                }
                $('.header-cart-delete').on('click', function () {
                    // $(this).parents(".header-cart-item").remove()
                    var rowid = $(this).attr('data-id');
                    $.ajax({
                        url: window.routeCartRemoveItem,
                        type: "POST",
                        dataType: 'json',
                        data: {
                            _token: window.csrfToken,
                            item: rowid
                        },
                        success: function (data) {
                            if (data.status == "success") {
                                getbasket();
                            } else {
                                swal({
                                    title: "Hata!",
                                    text: data.result,
                                    button: "Tamam",
                                    confirmButtonText: window.okay,

                                });
                            }
                        }
                    });


                })
            }
        }
    });

}


function setCookie(name, value, days) {
    var d = new Date;
    d.setTime(d.getTime() + 24*60*60*1000*days);
    document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}

function getCookie(name) {
    var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
}

function deleteCookie(name) { setCookie(name, '', -1); }

function isOnScreen(elem) {
    // if the element doesn't exist, abort
    if (elem.length == 0) {
        return;
    }
    var $window = jQuery(window)
    var viewport_top = $window.scrollTop()
    var viewport_height = $window.height()
    var viewport_bottom = viewport_top + viewport_height
    var $elem = elem
    var top = $elem.offset().top
    var height = $elem.height()
    var bottom = top + height

    return (top >= viewport_top && top < viewport_bottom) ||
        (bottom > viewport_top && bottom <= viewport_bottom) ||
        (height > viewport_height && top <= viewport_top && bottom >= viewport_bottom)
}

$(document).ready(function () {
    getbasket();
    $(".home-slide").owlCarousel({
        loop:true,
        nav:false,
        items:1,
        autoplay:true,
        dots:false,
    });

    $("#owl-home-slider").owlCarousel({
   
        slideSpeed: 300,
        paginationSpeed: 400,
        singleItem: true,
        items : 1,
        nav: true,
   
    });

    $('#loginform').on("submit", function (e) {

        $('.login-area-inner').loading({
            start : true,
            message: window.formLogin
        });
        e.preventDefault();
        var data = $(this).serialize();
        $.ajax({
            url: window.routeLogin,
            type: "POST",
            data: data,
            complete: function (data) {
                if(typeof data['responseJSON'] != 'undefined'){
                    var data = jQuery.parseJSON(data.responseText);
                    if (data.errors) {
                        var errortexts = "";
                        jQuery.each(data.errors, function (key, value) {
                            errortexts = errortexts + value + '<br>';
                        });

                        swal({
                            type: "error",
                            title: "Hata!",
                            text: data.result,
                            confirmButtonText: window.okay,
                            html: errortexts,
                            timer: window.timer,
                        }).then(function () {

                        })
                    }
                }else{
                    if ($.isEmptyObject(data.error)) {

                        window.location.href= window.loginurl;
                    }
                }


                $('.login-area-inner').loading('stop');

            },

        });
    });



    $('#newsletterform').on("submit", function (e) {

        e.preventDefault();
        var email = $(this).find('input[name="email"]').val();
        $.ajax({
            url: window.routeNewsletter,
            type: "POST",
            dataType: 'json',
            data: {
                _token: window.csrfToken,
                email: email
            },
            success: function (data) {

                if (data.status == "success") {
                    $('#newsletterform').find('input[name="email"]').val(" ");
                    Swal({
                        type: "success",
                        title: data.title,
                        text: data.result,
                        timer: window.timer,
                    });

                } else {
                    Swal({
                        type: "error",
                        title: data.title,
                        text: data.result,
                        timer: window.timer,
                    });
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {

            }
        });
    })






    $('.select2-basic').select2({
        minimumResultsForSearch: -1
    });

    if ($('#popupModal').length > 0){
        var img = $('#popupModal').find('img').attr('src');
        var filename = img.replace(/^.*[\\\/]/, '');
        if (localStorage.getItem("popup."+filename) === null) {
            var arr = []; // Array to hold the keys
            for (var i = 0; i < localStorage.length; i++){
                if (localStorage.key(i).substring(0,6) == 'popup.') {
                    arr.push(localStorage.key(i));
                }
            }
            for (var i = 0; i < arr.length; i++) {
                localStorage.removeItem(arr[i]);
            }
            localStorage.setItem("popup."+filename,'true');
            $('#popupmodaltrigger').trigger("click");
        }
    }
});





$(window).resize(function () {

});